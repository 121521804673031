<!--
  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<app-merlot-spinner *ngIf="!serviceOfferingData">
</app-merlot-spinner>

<ng-container *ngIf="serviceOfferingData">

  <ul class="list-group list-group-flush">

    <li class="list-group-item" style="border: none">
      <strong>Angebotstyp:</strong> <br />
      {{ serviceOfferingApiService.resolveFriendlyTypeName(getMerlotSpecificServiceOfferingTypeFromServiceOfferingSd(serviceOfferingData.selfDescription)) }}
    </li>

    <ng-container *ngIf="serviceOfferingData.providerDetails">
      <li class="list-group-item" style="border: none">
        <strong>Anbieter:</strong><br />
        {{ serviceOfferingData.providerDetails.providerLegalName }}
      </li>
    </ng-container>

    <li class="list-group-item" style="border: none">
      <strong>Status:</strong><br />
      {{ serviceOfferingApiService.resolveFriendlyStatusName(serviceOfferingData.metadata.state) }}
    </li>

    <li class="list-group-item" style="border: none">
      <strong>Erstelldatum:</strong><br />
      {{ serviceOfferingData.metadata.creationDate | date:"medium" }}
    </li>

    <li class="list-group-item" style="border: none">
      <strong>Letzte Änderung:</strong><br />
      {{ serviceOfferingData.metadata.modifiedDate | date:"medium"  }}
    </li>

  </ul>

  <div *ngFor="let vc of serviceOfferingData.selfDescription.verifiableCredential">

    <div *ngIf="isGxServiceOfferingCs(vc.credentialSubject)">
      <ul class="list-group list-group-flush">

        <li class="list-group-item" style="border: none">
          <strong>Service ID:</strong> <br />
          {{ asGxServiceOfferingCs(vc.credentialSubject).id }}
        </li>

        <li class="list-group-item" style="border: none">
          <strong>Name:</strong> <br />
          {{ asGxServiceOfferingCs(vc.credentialSubject)['gx:name'] }}
        </li>

        <li class="list-group-item" style="border: none" *ngIf="asGxServiceOfferingCs(vc.credentialSubject)['gx:description']">
          <strong>Beschreibung:</strong><br />
          {{ asGxServiceOfferingCs(vc.credentialSubject)['gx:description'] }}
        </li>

        <li class="list-group-item" style="border: none" *ngIf="asGxServiceOfferingCs(vc.credentialSubject)['gx:dataProtectionRegime']">
          <strong>Datenschutzsregelung:</strong><br />
          <ul *ngFor="let regime of asGxServiceOfferingCs(vc.credentialSubject)['gx:dataProtectionRegime']">
            <li>
              {{ regime }}
            </li>
          </ul>
        </li>

        <li class="list-group-item" style="border: none">
          <strong>Police:</strong><br />
          <ul *ngFor="let policy of asGxServiceOfferingCs(vc.credentialSubject)['gx:policy']">
            <li>
              {{ policy }}
            </li>
          </ul>
        </li>

        <li class="list-group-item" style="border: none">
          <strong>Datenexportmethode:</strong><br />
          <ul *ngFor="let dae of asGxServiceOfferingCs(vc.credentialSubject)['gx:dataAccountExport']">
            <li>
              Format: {{ dae["gx:formatType"] }}
              <br/>
              Zugriffstyp: {{ dae["gx:accessType"] }}
              <br/>
              Anfragetyp: {{ dae["gx:requestType"] }}
            </li>
          </ul>
        </li>

        <li class="list-group-item" style="border: none"  *ngIf="showVariableFields">
          <strong>Serviceangebotspezifische Geschäftsbedingungen:</strong><br />
          <ul *ngFor="let tnc of asGxServiceOfferingCs(vc.credentialSubject)['gx:termsAndConditions']">
            <li>
              <a [href]="tnc['gx:URL']">{{ tnc['gx:URL'] }}</a> (Hash: {{ tnc['gx:hash'] }})
            </li>
          </ul>
        </li>

      </ul>
    </div>

    <div *ngIf="isMerlotServiceOfferingCs(vc.credentialSubject)">
      <ul class="list-group list-group-flush">

        <li class="list-group-item" style="border: none" *ngIf="asMerlotServiceOfferingCs(vc.credentialSubject)['merlot:exampleCosts']">
          <strong>Beispielkosten:</strong><br />
          {{ asMerlotServiceOfferingCs(vc.credentialSubject)['merlot:exampleCosts'] }}
        </li>

        <li class="list-group-item" style="border: none" *ngIf="showVariableFields">
          <strong>Laufzeitoptionen:</strong><br />
          <ul>
            <ng-container *ngFor="let rt of asMerlotServiceOfferingCs(vc.credentialSubject)['merlot:runtimeOption']">
              <li>
                <ng-container *ngIf="rt['merlot:runtimeCount'] === 0 || rt['merlot:runtimeMeasurement'] === 'unlimited'; else notUnlimitedRuntime">
                  Unbegrenzt
                </ng-container>
                <ng-template #notUnlimitedRuntime>
                  {{ rt['merlot:runtimeCount'] }} {{ rt['merlot:runtimeMeasurement'] }}
                </ng-template>
              </li>
            </ng-container>
          </ul>
        </li>

      </ul>
    </div>

    <div *ngIf="isMerlotSaasServiceOfferingCs(vc.credentialSubject)">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" style="border: none" *ngIf="asMerlotSaasServiceOfferingCs(vc.credentialSubject)['merlot:hardwareRequirements']">
          <strong>Hardware-Anforderungen:</strong><br />
          {{ asMerlotSaasServiceOfferingCs(vc.credentialSubject)['merlot:hardwareRequirements'] }}
        </li>

        <li class="list-group-item" style="border: none" *ngIf="showVariableFields">
          <strong>Optionen für Anzahl erlaubter Benutzer:</strong><br />
          <ul>
            <ng-container *ngFor="let auc of asMerlotSaasServiceOfferingCs(vc.credentialSubject)['merlot:userCountOption']">
              <li>
                <ng-container *ngIf="auc['merlot:userCountUpTo'] === 0; else notUnlimitedUserCount">
                  Unbegrenzt
                </ng-container>
                <ng-template #notUnlimitedUserCount>
                  Bis zu {{ auc['merlot:userCountUpTo'] }} Benutzer
                </ng-template>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>

    </div>

    <div *ngIf="isMerlotDataDeliveryServiceOfferingCs(vc.credentialSubject)">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" style="border: none">
          <strong>Datenzugriffsart:</strong><br />
          {{ asMerlotDataDeliveryServiceOfferingCs(vc.credentialSubject)['merlot:dataAccessType'] }}
        </li>

        <li class="list-group-item" style="border: none">
          <strong>Datentransferart:</strong><br />
          {{ asMerlotDataDeliveryServiceOfferingCs(vc.credentialSubject)['merlot:dataTransferType'] }}
        </li>

        <li class="list-group-item" style="border: none" *ngIf="showVariableFields">
          <strong>Optionen für Anzahl erlaubter Datenaustausche:</strong><br />
          <ul>
            <ng-container *ngFor="let dec of asMerlotDataDeliveryServiceOfferingCs(vc.credentialSubject)['merlot:exchangeCountOption']">
              <li>
                <ng-container *ngIf="dec['merlot:exchangeCountUpTo']=== 0; else notUnlimitedExchangeCount">
                  Unbegrenzt
                </ng-container>
                <ng-template #notUnlimitedExchangeCount>
                  Bis zu {{ dec['merlot:exchangeCountUpTo'] }} Datenaustausche
                </ng-template>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>

    </div>

    <div *ngIf="isMerlotCoopContractServiceOfferingCs(vc.credentialSubject)">
      <ul class="list-group list-group-flush">
        <!-- no special fields currently -->
      </ul>
    </div>

  </div>

  <ul class="list-group list-group-flush">

      <li class="list-group-item" style="border: none">
        <strong>Selbstbeschreibung unterschrieben von:</strong><br />
        {{
          serviceOfferingData.metadata.signedBy ? serviceOfferingData.metadata.signedBy : "Unbekannt"
        }}
      </li>

  </ul>
</ng-container>