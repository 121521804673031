<!--
  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<ul class="list-group list-group-flush">
    <li class="list-group-item" style="border: none" *ngIf="shouldShowAttachments(contractDetails)">
        <input type="file" class="file-input" [accept]="'application/pdf'"
            (change)="addAttachment($event)" [hidden]="true" #fileUpload>

        <div class="file-upload">
            <strong>Vertragsspezifische Anhänge:</strong>
            <button cButton color="primary" variant="ghost" [disabled]="attachmentAddButtonDisabled" (click)="fileUpload.click()" size="sm" *ngIf="canAddAttachments(contractDetails)">
            <svg
            class=""
            cIcon
            name="cib-addthis"
            size="xl"
            ></svg></button>
        </div>
        <ul *ngFor="let attachment of contractDetails.negotiation.attachments;let index = index;trackBy: trackByFn">
            <li>
            <div>
                <a href="javascript:void(0);" (click)="downloadAttachment(attachment)">{{ attachment }}</a>
                <button cButton variant="ghost" [disabled]="attachmentAddButtonDisabled" (click)="deleteAttachment(attachment)" color="primary" size="sm" *ngIf="userIsActiveProvider() && isContractInDraft(contractDetails)">
                <svg
                class=""
                cIcon
                name="cil-trash"
                size="xl"
                ></svg></button>
            </div>
            </li>
        </ul>
        <app-status-message 
            [successMessage]="'Anhänge erfolgreich aktualisiert.'"
            [errorMessage]="'Fehler beim Speichern des Anhangs.'"
            [infoMessage]="'Anhänge werden aktualisiert.'"
            #attachmentStatusMessage>
        </app-status-message>
    </li>
    
    <div *ngFor="let vc of contractDetails.offering.selfDescription.verifiableCredential">

        <div *ngIf="isMerlotServiceOfferingCs(vc.credentialSubject)">
            <li class="list-group-item" style="border: none">
                <strong>Laufzeit*:</strong><br />
                <select class="form-select" [(ngModel)]="contractDetails.negotiation.runtimeSelection" [disabled]="contractDetails.details.state !== 'IN_DRAFT'">
                    <option [value]="" *ngIf="contractDetails.negotiation.runtimeSelection === ''"></option>
                    <option [ngValue]="rt['merlot:runtimeCount'] + ' ' + rt['merlot:runtimeMeasurement']" *ngFor="let rt of asMerlotServiceOfferingCs(vc.credentialSubject)['merlot:runtimeOption']">
                    <ng-container *ngIf="isRuntimeUnlimited(rt); else notUnlimitedRuntime">
                        Unbegrenzt
                    </ng-container>
                    <ng-template #notUnlimitedRuntime>
                        {{ rt['merlot:runtimeCount'] }} {{ rt['merlot:runtimeMeasurement'] }}
                    </ng-template>
                    </option>
                </select>
            </li>
        </div>

        <div *ngIf="isSaasContract(contractDetails) && isMerlotSaasServiceOfferingCs(vc.credentialSubject)">
            <li class="list-group-item" style="border: none" *ngIf="asMerlotSaasServiceOfferingCs(vc.credentialSubject)['merlot:userCountOption'] !== null">
                <strong>Anzahl erlaubter Benutzer*:</strong><br />
                <select class="form-select" [(ngModel)]="asSaasContract(contractDetails).negotiation.userCountSelection" [disabled]="!isContractInDraft(contractDetails)">
                <option [value]="" *ngIf="asSaasContract(contractDetails).negotiation.userCountSelection === ''"></option>
                <option [ngValue]="'' + auc['merlot:userCountUpTo']" *ngFor="let auc of asMerlotSaasServiceOfferingCs(vc.credentialSubject)['merlot:userCountOption']">
                    <ng-container *ngIf="auc['merlot:userCountUpTo'] === 0; else notUnlimitedUserCount">
                    Unbegrenzt
                    </ng-container>
                    <ng-template #notUnlimitedUserCount>
                    Bis zu {{ auc['merlot:userCountUpTo'] }}
                    </ng-template>
                </option>
                </select>
            </li>
        </div>

        <div *ngIf="isDataDeliveryContract(contractDetails) && isMerlotDataDeliveryServiceOfferingCs(vc.credentialSubject)">
            <li class="list-group-item" style="border: none" *ngIf="asMerlotDataDeliveryServiceOfferingCs(vc.credentialSubject)['merlot:exchangeCountOption'] !== null">
                <strong>Anzahl erlaubter Datenaustausche*:</strong><br />
                <select class="form-select" [(ngModel)]="asDataDeliveryContract(contractDetails).negotiation.exchangeCountSelection" [disabled]="contractDetails.details.state !== 'IN_DRAFT'">
                <option [value]="" *ngIf="asDataDeliveryContract(contractDetails).negotiation.exchangeCountSelection === ''"></option>
                <option [ngValue]="'' + dec['merlot:exchangeCountUpTo']" *ngFor="let dec of asMerlotDataDeliveryServiceOfferingCs(vc.credentialSubject)['merlot:exchangeCountOption']">
                    <ng-container *ngIf="dec['merlot:exchangeCountUpTo'] === 0; else notUnlimitedExchangeCount">
                    Unbegrenzt
                    </ng-container>
                    <ng-template #notUnlimitedExchangeCount>
                    Bis zu {{ dec['merlot:exchangeCountUpTo'] }}
                    </ng-template>
                </option>
                </select>
            </li>
        </div>

    </div>
    
    <li class="list-group-item" style="border: none">
        <div class="mb-3">
            <label class="form-label"><strong>Zusatzvereinbarungen</strong></label>
            <input class="form-control" placeholder="Keine Zusatzvereinbarungen" 
            [disabled]="userIsActiveConsumer() || contractDetails.details.state !== 'IN_DRAFT'" 
            [(ngModel)]="contractDetails.negotiation.additionalAgreements">
        </div>
    </li>
</ul>