<div class="expanded">
  <form [formGroup]="form">
    <div *ngIf="!input.required && formGroups.length == 0" flex fxLayoutAlign="start center" layout="row">
      
      <div class="flex-container">

      <span>{{input.name}} hinzufügen</span>

      <button cButton color="primary" variant="ghost" (click)="addInitialShape()" [disabled]="!enableButton" size="sm">
        <svg
        class=""
        cIcon
        name="cib-addthis"
        size="xl"
      ></svg></button>
      </div>

    </div>
    <mat-accordion *ngFor="let nestedShapeForm of formGroups; let i = index">
      <div class="flex-container">
      <mat-expansion-panel (closed)="panelOpenState = false" (opened)="panelOpenState = true" class="flex-item-grow" [expanded]="input.required && (i < input.minCount)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{inputs[i].name}}<span *ngIf="input.required && (i < input.minCount)" aria-required="true"
                                                                    class="required">*</span><span class="field-key">&nbsp;&nbsp;{{ input.key }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [formGroup]="nestedShapeForm">
          <mat-error *ngIf="inputs[i].childrenFields.length==0">
            Das Schema konnte in der Datei nicht gefunden werden.
          </mat-error>
          <div *ngFor="let child of inputs[i].childrenFields">
            <app-dynamic-form-input *ngIf="child.componentType == 'dynamicFormInput'" [form]="nestedShapeForm"
                                    [input]="child" [shapes]="shapes" #formInput></app-dynamic-form-input>
            <app-dynamic-form-array *ngIf="child.componentType == 'dynamicFormArray'" [form]="nestedShapeForm"
                                    [input]="child" [shapes]="shapes" #formArray></app-dynamic-form-array>
            <app-dynamic-form-or *ngIf="child.componentType == 'dynamicFormOr'" [form]="nestedShapeForm" [input]="child"
                                 [shapes]="shapes" #formOr></app-dynamic-form-or>
            <app-dynamic-form-or-array *ngIf="child.componentType == 'dynamicFormOrArray'" [form]="nestedShapeForm"
                                       [input]="child" [shapes]="shapes" #formOrArray></app-dynamic-form-or-array>
            <app-expanded-fields *ngIf="child.componentType == 'dynamicExpanded' && !child.selfLoop"
                                 [form]="nestedShapeForm"
                                 [input]="child" [parentId]="input.id" [shapes]="shapes" #expandedFields></app-expanded-fields>
            <app-dynamic-self-loops *ngIf="child.selfLoop" [form]="nestedShapeForm" [input]="child"
                                    [parentId]="input.id" [shapes]="shapes" #selfLoops>
            </app-dynamic-self-loops>
          </div>
        </div>
      </mat-expansion-panel>

        <button cButton color="primary" variant="ghost" (click)="addInput()" *ngIf="i==0 && displayAddButton" [disabled]="!enableButton" size="sm">
          <svg
          class=""
          cIcon
          name="cib-addthis"
          size="xl"
        ></svg></button>

        <button cButton variant="ghost" (click)="deleteInput(i)" *ngIf="displayDeleteButton(i)" color="primary" size="sm">
          <svg
          class=""
          cIcon
          name="cil-trash"
          size="xl"
        ></svg></button>
    </div>

      <div flex fxLayoutAlign="start center" layout="row">
        <mat-error>
          <app-show-errors [ctrl]="nestedShapeForm" [label]="input.name"></app-show-errors>
        </mat-error>
      </div>
    </mat-accordion>
  </form>
</div>
