<!--
  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<ul class="list-group list-group-flush">
    <div *ngIf="isDataDeliveryContract(contractDetails)">

        <li class="list-group-item" style="border: none">
            <strong>Typ der Datenadresse*</strong><br />
            <select class="form-select" [(ngModel)]="selectedTransferMethod" (change)="onChangeTransferType()" [disabled]="!isContractInDraft(contractDetails) && !isContractSignedConsumer(contractDetails)">
                <option [value]="''"></option>
                <option [value]="'IonosS3Source'">Ionos S3</option>
            </select>
        </li>

        <li class="list-group-item" style="border: none" *ngIf="asDataDeliveryContract(contractDetails).provisioning.providerTransferProvisioning">
            <strong>Aktiver EDC Connector*:</strong><br />
            <select class="form-select" [(ngModel)]="asDataDeliveryContract(contractDetails).provisioning.providerTransferProvisioning.selectedConnectorId" [disabled]="!isContractInDraft(contractDetails) && !isContractSignedConsumer(contractDetails)">
            <option [value]="" *ngIf="getSelectedProviderConnectorId() === ''"></option>
            <option [value]="connector.connectorId" *ngFor="let connector of availableConnectors">
                {{ connector.connectorId }}
            </option>
            </select>
        </li>
        
        <div *ngIf="isIonosProviderTransferProvisioning(contractDetails)">

            <li class="list-group-item" style="border: none">
                <strong>IONOS-S3 Quell-Bucket*</strong><br />
                <select class="form-select" [(ngModel)]="asIonosProviderTransferProvisioning(asDataDeliveryContract(contractDetails).provisioning.providerTransferProvisioning).dataAddressSourceBucketName" [disabled]="!isContractInDraft(contractDetails) && !isContractSignedConsumer(contractDetails)">
                <option [value]="" *ngIf="asIonosProviderTransferProvisioning(asDataDeliveryContract(contractDetails).provisioning.providerTransferProvisioning).dataAddressSourceBucketName === ''"></option>
                <option [value]="bucket" *ngFor="let bucket of getConnectorBuckets(getSelectedProviderConnectorId())">
                    {{ bucket }}
                </option>
                </select>
            
                <div *ngIf="isConnectorIdValid(getSelectedProviderConnectorId()) && !isAnyBucketAvailableForConnector(getSelectedProviderConnectorId())" style="color: red">
                <small>
                Es ist kein IONOS-S3 Bucket mit diesem EDC Connector verknüpft. Sie können den EDC Connector und die Liste der assoziierten IONOS-S3 Buckets über "Meine Organisation bearbeiten" bearbeiten.
                </small>
                </div>
            </li>
            
            <li class="list-group-item" style="border: none">
                <div class="mb-3">
                <label class="form-label"><strong>Quell-Dateipfad im IONOS-S3 Bucket*</strong></label>
                <input class="form-control" placeholder="z.B. MyFile1.json oder QuellPfad/" 
                    [(ngModel)]="asIonosProviderTransferProvisioning(asDataDeliveryContract(contractDetails).provisioning.providerTransferProvisioning).dataAddressSourceFileName" [disabled]="!isContractInDraft(contractDetails) && !isContractSignedConsumer(contractDetails)">
                </div>
            </li>
        </div>
    </div>


    <li class="list-group-item" style="border: none">
    <c-form-check inline>
        <input cFormCheckInput id="checkMerlotAGB" type="checkbox" [(ngModel)]="contractDetails.negotiation.providerTncAccepted" [disabled]="!isContractInDraft(contractDetails) && !isContractSignedConsumer(contractDetails)">
        <label cFormCheckLabel for="checkMerlotAGB" style="padding-left: 10px;"><b>Allgemeine Geschäftsbedingungen des Service-Angebotes & des Merlot Marktplatzes gelesen und akzeptiert*</b></label>
    </c-form-check>
    </li>
</ul>
          