<!--
  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<app-merlot-spinner *ngIf="!prefillDone.value">
</app-merlot-spinner>

<m-card title="Gaia-X Service Offering">
    <app-base-wizard-extension #gxServiceOfferingWizard></app-base-wizard-extension>
</m-card>
<m-card title="MERLOT Service Offering">
    <app-base-wizard-extension #merlotServiceOfferingWizard></app-base-wizard-extension>
</m-card>
<m-card title="MERLOT Service Offering Type Specifics">
    <app-base-wizard-extension #merlotSpecificServiceOfferingWizard></app-base-wizard-extension>
</m-card>

<div class="d-flex flex-row docs-highlight mb-2 justify-content-center">
    <div class="p-2 docs-highlight">
        <button
            [disabled]="isWizardFormInvalid() || submitButtonsDisabled"
            cButton color="primary" (click)="onSubmit(false)">
            {{ 'Änderungen speichern' }}
        </button>
    </div>
    <div class="p-2 docs-highlight">
        <button
        [disabled]="isWizardFormInvalid() || submitButtonsDisabled"
        cButton 
        color="primary"
        (click)="onSubmit(true)"
        >
        Veröffentlichen
        </button>
    </div>
</div>
<app-merlot-spinner *ngIf="waitingForResponse">
</app-merlot-spinner>
<div class="d-flex flex-row docs-highlight mb-2 justify-content-center">
    <div class="p-2 docs-highlight">
        <app-status-message 
              [successMessage]="'Selbstbeschreibung erfolgreich gespeichert!'"
              [errorMessage]="'Fehler beim Speichern der Selbstbeschreibung.'"
              [infoMessage]="''"
              #saveStatusMessage>
        </app-status-message>
    </div>
</div>