
<div class="register-content">
  <div *ngIf="!requestSuccess" class="error-message">
    <mat-error>{{ "form.displayError" | translate }}</mat-error>
    {{ reportError("form.displayError") }}
    <br /><br />
    <a color="primary" mat-raised-button>
      {{ "form.ttlError" | translate }}
    </a>
  </div>
  <div *ngIf="requestSuccess">
    <form [formGroup]="form">
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div
          *ngFor="let group of groupedFormFields; let i = index"
          [ngClass]="{ 'position-center': groupsNumber === 1 }"
          fxFlex="70%"
          fxFlex.sm="100%"
          fxFlex.xs="100%"
        >
          <div class="mb-3" >
            <label class="form-label">Subject-ID*<span class="field-key">&nbsp;&nbsp;id</span></label>
            <input
              type="text"
              class="form-control"
              formControlName="user_prefix"
              placeholder="did:example:123456789abcdefghi"
            />
          </div>

          <div
            *ngFor="let formField of group; let j = index"
            class="form-group"
          >
            <span *ngIf="j == 0">{{ formField.group }}</span>

            <app-dynamic-form-input
              *ngIf="formField.componentType == 'dynamicFormInput'"
              [form]="form"
              [input]="formField"
              [shapes]="file.shapes"
              #formInput
            ></app-dynamic-form-input>
            <app-dynamic-form-array
              *ngIf="formField.componentType == 'dynamicFormArray'"
              [form]="form"
              [input]="formField"
              [shapes]="file.shapes"
              #formArray
            ></app-dynamic-form-array>
            <app-dynamic-form-or
              *ngIf="formField.componentType == 'dynamicFormOr'"
              [form]="form"
              [input]="formField"
              [shapes]="file.shapes"
              #formOr
            ></app-dynamic-form-or>
            <app-dynamic-form-or-array
              *ngIf="formField.componentType == 'dynamicFormOrArray'"
              [form]="form"
              [input]="formField"
              [shapes]="file.shapes"
              #formOrArray
            ></app-dynamic-form-or-array>
            <app-expanded-fields
              *ngIf="formField.componentType == 'dynamicExpanded'"
              [form]="form"
              [input]="formField"
              [shapes]="file.shapes"
              #expandedFields
            ></app-expanded-fields>
            <app-dynamic-self-loops
              *ngIf="formField.componentType == 'dynamicSelfLoop'"
              [form]="form"
              [input]="formField"
              [shapes]="file.shapes"
              #selfLoops
            ></app-dynamic-self-loops>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
